var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ModalForm", {
    attrs: { title: "Display settings" },
    on: { close: _vm.onClose },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _c("Form", {
              scopedSlots: _vm._u([
                {
                  key: "body",
                  fn: function() {
                    return [
                      _c(
                        "FormSection",
                        { attrs: { title: "Table grid" } },
                        [
                          _c("Select", {
                            attrs: {
                              label: "Row height",
                              options: _vm.rowHeightOptions
                            },
                            model: {
                              value: _vm.rowHeightDescription,
                              callback: function($$v) {
                                _vm.rowHeightDescription = $$v
                              },
                              expression: "rowHeightDescription"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isCollectionLayout
                        ? _c(
                            "FormSection",
                            { attrs: { title: "Image grid" } },
                            [
                              _c("Select", {
                                attrs: {
                                  label: "Number of columns",
                                  options: _vm.columnsNumberOptions
                                },
                                model: {
                                  value: _vm.columnsNumberDescription,
                                  callback: function($$v) {
                                    _vm.columnsNumberDescription = $$v
                                  },
                                  expression: "columnsNumberDescription"
                                }
                              }),
                              _vm._v(" "),
                              _c("Select", {
                                attrs: {
                                  label: "Image scaling",
                                  options: _vm.objectFitOptions
                                },
                                model: {
                                  value: _vm.objectFitDescription,
                                  callback: function($$v) {
                                    _vm.objectFitDescription = $$v
                                  },
                                  expression: "objectFitDescription"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  },
                  proxy: true
                },
                {
                  key: "submit",
                  fn: function() {
                    return [
                      _c("Button", {
                        attrs: { title: "SAVE SETTINGS", size: _vm.smallSize },
                        nativeOn: {
                          click: function($event) {
                            return _vm.onApply.apply(null, arguments)
                          }
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "proceed",
                  fn: function() {
                    return [
                      _c("Button", {
                        attrs: {
                          theme: _vm.theme.SECONDARY,
                          title: _vm.$t("@Core._.buttons.cancel"),
                          size: _vm.smallSize
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.onClose.apply(null, arguments)
                          }
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }