var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Fab", {
    attrs: { theme: _vm.secondaryTheme },
    nativeOn: {
      click: function($event) {
        return _vm.onActivate.apply(null, arguments)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "icon",
        fn: function() {
          return [
            _c("IconListMedium", { attrs: { "fill-color": _vm.fillColor } })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }